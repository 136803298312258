import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadSampleDispatchHeadersUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { SampleDispatchHeader, SampleDispatchHeaderResult } from '../types';

export const loadSampleDispatchHeaders = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: SampleDispatchHeader[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const url = buildLoadSampleDispatchHeadersUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<SampleDispatchHeaderResult>) => {
            const { SampleDispatchHeader } = response;
            const result = convertToCamel<SampleDispatchHeader[]>(SampleDispatchHeader);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
